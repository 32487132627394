<template>
  <div id="authorityList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addAuthority"
              type="primary"
              @click="$refs.addAuthority.show = true"
              v-if="buttonList.includes('authority-addAuthority')"
              ><i class="iconfont icon-jiahao"></i> 新增权限</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="roleName" label="权限名称" align="center">
        </el-table-column>
        <el-table-column prop="updateAt" label="更新时间" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="buttonList.includes('authority-editBtn')"
              @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="buttonList.includes('authority-delBtn')"
              @click="del(scope.row.roleId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <add-authority ref="addAuthority" @getList="getList" />
  </div>
</template>
<script>
export default {
  components: {
    addAuthority: () => import("./addAuthority.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    edit(row) {
      this.$refs.addAuthority.isEdit = true;
      this.$refs.addAuthority.roleId = row.roleId;
      this.$refs.addAuthority.roleDetail = row.roleDetail;
      this.$refs.addAuthority.authorityForm.name = row.roleName;
      this.$refs.addAuthority.show = true;
    },
    del(roleId) {
      this.$confirm("此操作将永久删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/role/delete/${roleId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/role/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.updateAt = this.$moment(item.updateAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#authorityList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addAuthority {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
